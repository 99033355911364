<template>
    <answer-panel
    url="/api/mistakeList/wrongQuestionPage"
    :showAutoRemove="true"
    >
        <template slot="top">
            <div>
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>错题本</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="title">错题本</div>
        </template>

    </answer-panel>
</template>

<script>

import AnswerPanel from "../components/AnswerPanel.vue"

export default {

    components: {
        AnswerPanel
    },

}
</script>